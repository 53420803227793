import axios from 'axios';
import { getAccessTokenAppRouter } from '../helpers/getAccessTokenAppRouter';
import { getApplicationTokenAppRouter } from '../helpers/getApplicationTokenAppRouter';

const BASE_URL = process.env.NEXT_PUBLIC_API;

function getGatewayURL(endpoint) {
  return `${BASE_URL}${endpoint}`;
}

export async function appRouterServerSideApi(
  endpoint,
  cookies,
  method = 'GET',
  params = null,
  body = null,
  headers = {},
) {
  let options = {
    method,
    url: getGatewayURL(endpoint),
    params,
    data: body,
    headers: {
      'Content-Type': 'application/json',
      'X-UserSession': `${cookies.get('user_session_id')?.value ? cookies.get('user_session_id')?.value : ''}`,
      ...headers,
    },
  };
  try {
    options['headers']['X-ApplicationToken'] = await getApplicationTokenAppRouter(cookies);
    options['headers']['Authorization'] = `Bearer ${await getAccessTokenAppRouter(cookies)}`;
    return await axios(options);
  } catch (error) {
    if (
      error.response.status == 401 &&
      error?.response?.data?.detail == 'profile status changed.'
    ) {
      options['headers']['X-ApplicationToken'] = await getApplicationTokenAppRouter(cookies);
      options['headers']['Authorization'] = `Bearer ${await getAccessTokenAppRouter(
        cookies,
        true,
      )}`;
      return await axios(options);
    } else if (
      error.response.status == 401 &&
      (error?.response?.data?.detail == 'Not authenticated' ||
        error?.response?.data?.detail == 'Unauthorized')
    ) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    } else {
      throw new Error(error.message);
    }
  }
}
